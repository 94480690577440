import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import { Link } from "gatsby";

import { Button } from "antd";
import { formatURL, makeJulianDate, removeEmptyKeys } from "../../../helpers";

import "./day-calendar.scss";
import { useThemeContext } from "../../Layout/ThemeContext";

const FETCH_QUERY = gql`
	query calendarDataQuery($currentDate: date!) {
		days(where: { date: { _eq: $currentDay } }) {
			date
			name
			old_date_style
			new_date_style
			day: title
			week {
				title
			}
			glas_name
			days_posts {
				post {
					title
					description
					type
					slug
				}
			}
			memorial_days(where: {saint: {in_calendar: {_eq: true}}}) {
				saint {
					title
					rank {
						short_name
					}
					slug
					type

					ikons_saints {
						ikon {
							title_full
							slug
							type
							main_image
						}
					}
				}
			}
			gospels {
		      	gospel_kind {
		        	title
		      	}
		      	saint_text {
		        	saint_book {
			          	slug
			          	name
		        	}
		      	}
		    }
			food {
				name
			}
		}
	}
`;

export function DayCalendar() {
	const { theme } = useThemeContext();

	const [isMount, setIsMount] = useState(false);
	const [calendarData, setCalendarData] = useState({});

	const currentDate = makeJulianDate("-");

	const { loading, data } = useQuery(
		FETCH_QUERY,
		{
			variables: { currentDate },
			fetchPolicy: "cache-and-network",
			ssr: false,
		},
	);

	useEffect(() => {
		setIsMount(true);
	}, []);

	useEffect(() => {
		if (isMount) {
			setCalendarData(get(data, "days[0]", {}));
		}
	}, [loading]);

	return (
		<div className={"row day-calendar"}>
			<div className={"col-12"}>
				<div className={"d-flex justify-content-space-around flex-xs-wrap flex-md-nowrap"}>
					<div
						className={"d-flex justify-content-between day-calendar_image"}
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						{
							calendarData.dayicon_today && (
								<div>
									<img
										src={get(calendarData, "memorial_days[0].saint.ikons_saints[0].main_image.src", "")}
										alt={get(calendarData, "memorial_days[0].saint.ikons_saints[0].main_image.src", "")}
									/>
								</div>
							)
						}
					</div>
					<div className={"day-calendar__text-block"}>
						{
							calendarData.sanitized_day && (
								<div
									className={"h4 day-calendar_title"}
									style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.h4,
										lineHeight: theme.lineHeight.h4,
										color: theme.color.text,
									})}
								>
									{calendarData.sanitized_day}
								</div>
							)
						}
						{
							calendarData.ned && (
								<div
									style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.text,
										lineHeight: theme.lineHeight.text,
										color: theme.color.text,
									})}
								>
									{calendarData.sanitized_ned}
								</div>
							)
						}
						<div className="text">
							{
								calendarData.sanitized_post && (
									<span
										className="text"
										style={removeEmptyKeys({
											fontFamily: theme.fontFamily,
											fontSize: theme.fontSize.text,
											lineHeight: theme.lineHeight.text,
											color: theme.color.text,
										})}
										dangerouslySetInnerHTML={{ __html: calendarData.sanitized_post }}
									/>
								)
							}
							{
								calendarData.trapeza_txt && (
									<span
										className="text"
										style={removeEmptyKeys({
											fontFamily: theme.fontFamily,
											fontSize: theme.fontSize.text,
											lineHeight: theme.lineHeight.text,
											color: theme.color.text,
										})}
										dangerouslySetInnerHTML={{ __html: get(calendarData, "orthodox_dates.trapeza_txt", "") }}
									/>
								)
							}
						</div>
						<div
							className={"text"}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.text,
							})}
						>
							{
								calendarData.sanitized_para && calendarData.sanitized_para
							}
						</div>
						<div className={"d-flex justify-content-space-around text day-calendar_link"}>
							<Link to={formatURL(`/calendar/${calendarData.date}`)}>
								<Button
									className={"button-white"}
									style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.text,
										lineHeight: theme.lineHeight.text,
										color: theme.color.body,
										backgroundColor: theme.color.text,
									})}
								>
									Подробнее
								</Button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
