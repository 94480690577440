import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { get, compact, last } from "lodash";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { isMobile } from "react-device-detect";

import Banner from "../../components/Banner";
import { TextBlock, ContentBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import { PureCarousel } from "../../components/PureReactCarousel";
import Pages from "../../components/Pages";
import Collapse from "../../components/Collapse";
import { makeUrl, makeNews, makeArticles, makeBooks, removePreloader, makeVirtualTourSlides } from "../../helpers";
import { DayCalendar } from "../../components/Calendar/DayCalendar";
import MapComponent from "../../components/Map";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share, Weather } from "../../widgets";
import Contacts from "../../components/Contacts";
import { MetaImage, MetaName, MetaAddress, MetaPublicAccess, MetaTelephone, MetaOpeningHours, MetaGeo } from "../../components/Meta";
import { TEMPLE_MAX_UPDATE, TEMPLE } from "../../queries/queries.graphql";

export const query = graphql`
	query templesQuery($slug: String!) {
		hasura {
			...Temple
		}
	}
`;

export default function Temples({ location, data, pageContext }) {
	const path = last(compact(get(location, "pathname", "").split("/")));

	const [temples, setTemples] = useState(get(data, "hasura.temples[0]", []));

	const maxUpdated = new Date(get(data, "hasura.temples_aggregate.aggregate.max.updated_at", new Date()));

	const templeOptions = {
		fetchPolicy: "no-cache",
		variables: {
			location: path,
		},
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(TEMPLE_MAX_UPDATE, templeOptions);
	const [loadTemple, { loading: dataLoading, called, data: fetchData, error: dataError }] = useLazyQuery(TEMPLE, templeOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "temples_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadTemple();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load templeMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (called && fetchData && !dataError) {
			setTemples(get(fetchData, "temples[0]", {}));
		}

		removePreloader(!dataLoading || dataError);
	}, [dataLoading]);

	const bannerSlides = [
		{
			title: get(temples, "page_title_full", ""),
			publicURL: get(temples, "main_image.src"),
			mainImageMobile: get(temples, "main_image_mobile.src", ""),
		},
	];

	const {
		main_image,
		title_full,
		telephone,
		openning_hours,
		address,
		main_image_preview,
		location_object,
		page_title_short,
		page_title_full,
	} = temples;

	const url = makeUrl.temple(temples);
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));
	const content_blocks = get(temples, "content_blocks", []);
	const books = makeBooks(get(temples, "books", []));
	const news = makeNews(get(temples, "news_temples", []).map(item => item.news));
	const mediaArticles = makeArticles(get(temples, "media_articles_temples", []).map(item => item.media_article));
	const coordinates = get(
		get(temples, "location_object.features", []).filter(
			feature => get(feature, "geometry.type", "") === "Point",
		),
		"[0].geometry.coordinates",
		[],
	);
	const weather = get(temples, "city.weather", {});
	const virtualTour = makeVirtualTourSlides(get(temples, "temples_virtual_tours", []));
	const monasteryName = pageContext?.monastery?.slug;

	return (
		<Pages entity={temples} url={url} >
			<div itemScope itemType="https://schema.org/Church">
				<MetaPublicAccess content="true" />
				<MetaGeo content={coordinates} />
				<MetaImage content={imageSrc} />
				<MetaName content={title_full} />
				<MetaTelephone content={telephone} />
				<MetaOpeningHours content={openning_hours} />
				<MetaAddress content={address} />
				<ContentBlock key={"banner"}>
					<Banner slides={bannerSlides}>
						{isMobile && <Weather weather={weather} />}
					</Banner>
				</ContentBlock>
				<ContentBlock key={"breadcrumbs"}>
					<div className={"container d-flex justify-content-between"}>
						<Breadcrumbs currentLabel={title_full} pageContext={pageContext} />
						{!isMobile && <Weather weather={weather} />}
					</div>
				</ContentBlock>
				<ContentBlock key={"mainContent"}>
					<div className={"container"}>
						<Collapse
							config={{
								maxHeight: 600,
							}}
							contentBlocks={content_blocks}
						>
							<Contents items={content_blocks} />
						</Collapse>
					</div>
				</ContentBlock>
				{
					monasteryName !== "svyato-uspenskii-muzhskoi-monastir---sarovskaya-pustin" ||
					pageContext.id !== 24 ||
					pageContext.id !== 25 ||
					pageContext.id !== 21 && (
						<ContentBlock className={"bg-accent_light"} key={"calendar"}>
							<div className={"container"}>
								<TextBlock title={"Православный календарь"}>
									<DayCalendar />
								</TextBlock>
							</div>
						</ContentBlock>
					)
				}

				<ContentBlock key={"news"} mount={news && !!news.length}>
					<div className={"container"}>
						<TextBlock title={"Новости"} list={"Смотреть всё"} listLink={makeUrl.contentNews(pageContext)} allowBtn={news && news.length > 5}>
							<PureCarousel
								type={"SlideNews"}
								items={news}
								mediaSize={["100%", 400]}
								slideRatio={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 4,
								}}
								slideRatioMobile={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 4,
								}}
								params={{
									slidesPerView: 4,
									breakpoints: [
										{
											width: 0,
											slidesPerView: 1,
										},
										{
											width: 768,
											slidesPerView: 2,
										},
										{
											width: 950,
											slidesPerView: 3,
										},
									],
								}}
							/>
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock key={"articles"} mount={mediaArticles && !!mediaArticles.length}>
					<div className={"container"}>
						<TextBlock title={"Статьи"}>
							<PureCarousel
								type={"SlideNews"}
								items={mediaArticles}
								mediaSize={["100%", 400]}
								slideRatio={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 5.5,
								}}
								slideRatioMobile={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 4,
								}}
								params={{
									slidesPerView: 4,
									breakpoints: [
										{
											width: 0,
											slidesPerView: 1,
										},
										{
											width: 576,
											slidesPerView: 2,
										},
										{
											width: 768,
											slidesPerView: 3,
										},
										{
											width: 1136,
											slidesPerView: 4,
										},
									],
								}}
							/>
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock mount={books && !!books.length} key={"books"}>
					<div className={"container"}>
						<TextBlock title={"Книги"}>
							<PureCarousel type={"SlideContent"} items={books} mediaSize={[300, 400]} />
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock key={"virtual_tours"} mount={virtualTour && !!virtualTour.length}>
					<div className={"container"} >
						<TextBlock title={"Виртуальные экскурсии"} id={"Virtual-Tours"}>
							<PureCarousel
								type={"SlideLinks"}
								items={virtualTour}
								slideRatio={{
									naturalSlideWidth: 2,
									naturalSlideHeight: 1,
								}}
								slideRatioMobile={{
									naturalSlideWidth: 1,
									naturalSlideHeight: 0.8,
								}}
								params={{
									slidesPerView: 2,
									breakpoints: [
										{
											width: 0,
											slidesPerView: 1,
										},
										{
											width: 576,
											slidesPerView: 2,
										},
										{
											width: 768,
											slidesPerView: 2,
										},
										{
											width: 1136,
											slidesPerView: 2,
										},
									],
								}}
							/>
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock mount={temples && address || openning_hours || telephone} key={"contacts"}>
					<div className={"container"}>
						<div className={"row"}>
							<div className={"col-12"}>
								<TextBlock title={"Контакты"} className={"pb-2"}/>
							</div>
						</div>
						<Contacts address={address} hours={openning_hours} telephone={telephone}/>
					</div>
				</ContentBlock>
				<ContentBlock mount={temples && location_object} key={"map"}>
					<div className={"container"}>
						<div className={"row"}>
							<div className={"col-12"}>
								<TextBlock title={"Расположение"} />
							</div>
						</div>
					</div>
					<MapComponent items={[temples]} showPolygon />
				</ContentBlock>
				<div className={"container"}>
					<Share
						url={url}
						pageTitleShort={page_title_short}
						pageTitleFull={page_title_full}
						imageSrc={imageSrc}
					/>
				</div>
			</div>
		</Pages>
	);
}

Temples.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
};

Temples.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
	pageContext: {},
};
